import React from "react";

function getDocumentFragment(text) {
    const f = document.createRange().createContextualFragment(text);
    return f;
  }
  
class FragmentRenderer extends React.Component {
    constructor(props) {
      super(props);
  
      this.setRef = this.setRef.bind(this);
    }
  
    setRef(ref) {
      this.ref = ref;
    }
  
    componentDidMount() {
      this.appendFragment();
    }
  
    componentDidUpdate(prevProps) {
      if (prevProps.text != this.props.text) {
        this.appendFragment();
      }
    }
  
    appendFragment() {
      if (!this.ref) {
        return;
      }
      while (this.ref.firstChild) {
        this.ref.removeChild(this.ref.firstChild);
      }
      this.ref.appendChild(getDocumentFragment(this.props.text));
    }

    render() {
        return React.createElement("div", {
          ref: this.setRef
        });
      }
}

export default FragmentRenderer;