import $ from 'jquery';
import { useEffect } from 'react';
import createRipple from './createRipple';
import * as bootstrap from 'bootstrap/dist/js/bootstrap.esm.min.js';
import AOS from 'aos';
import "aos/dist/aos.css";


export default () => {
    useEffect(() => {

        // Initialize animate on scroll
        AOS.init({
            once: true, // whether animation should happen only once - while scrolling down
        });

        document.body.style.overflow = "auto";
        document.getElementById("copyright-year").innerHTML = (new Date().getFullYear());

        // Always scroll to the top when full refresh
            // $('html, body').animate({ scrollTop: 0 }, '300');
    
        // Show the pre-dataloader
        $('.preloader-background').delay(2500).fadeOut('slow');
        $('.preloader-wrapper')
            .delay(2500)
            .fadeOut(function() {
                // Reset the browser scrollbar
                $('body').addClass('of-y').removeClass('scroll-down');;
            });
        $('capp-btn btn-outline-text-link white lang-drop-btn').prepend(`<i class="fa fa-globe">`);
        
        // Bind the click event listener for buttons to have ripple effect
        const btnElem = document.getElementsByClassName('capp-btn');
        for (const button of btnElem) {
            button.addEventListener('click', createRipple);
        }
    
        // If URL has hashtag then scroll to the respective section
        if (window.location.hash) {
            var hash = window.location.hash;
            
            if ($(hash).length) {
                $('html, body').animate({
                    scrollTop: $(hash).offset().top - 70
                }, 900, 'swing');
            }
        }

        //Contact Us
        if($('a.contact-me').length > 0)
        {
            $('a.contact-me').on('click', function(){
                window.open("https://globaletrade.services/contactus#block-yui_3_17_2_3_1440149601640_32462", "_blank");
            });
        }

        // Initialize bootstrap popover components
        [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]')).map(function (e) {
            var n = e.attributes['data-content-id'];
            if (n) {
                n = $(`#${n.value}`).html();
                return new bootstrap.Popover(e, {
                    content: n,
                    html: !0
                });
            }
        });

        const popoverLocale = document.getElementById('ca-locale');

        var temp = $(window).height();
        //On scroll event listener
        $(window).scroll(function () {

            bootstrap.Popover.getInstance(popoverLocale).hide();

            if ($(window).scrollTop() > temp) {
                $('#back-top a').addClass('d-block action-go-to-top-active');
                $('#back-top a').removeClass('action-go-to-top-blur d-none');
            }
            else {
                $('#back-top a').addClass('action-go-to-top-blur');
                $('#back-top a').removeClass('d-block');
            }
        });

        //Animate the smooth scroll
        $('#go-to-top').on('click', function () {
            $("html, body").animate({ scrollTop: 0 }, 300);
            return false;
        });

        return () => {
            document.body.style.removeProperty('overflow');

            for (const button of btnElem) {
                button.removeEventListener('click', createRipple);
            }
        }
    })
}